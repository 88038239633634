import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useEffect } from "react";
import styles from './css/register.module.css'

import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { Footer, Navbar } from 'components/AuthCommon'

import { withRouter, Link } from "react-router-dom";
import Preloader from "components/Common/Preloader";
import Login from "./Login";
import FindUser from "./FindUser";
import { useSelector } from "react-redux";

const fullUrl = window.location.hostname.split('.')
const [_subdomain, _undefined] = window.location.hostname.split('.')
const isSubdomainPresent = fullUrl.length > 1 && _subdomain != 'app'
import { setSubdomain } from "store/actions";
import { useDispatch } from "react-redux";

const LoginControl = () => {
  const { subdomain } = useSelector((state) => state.Login);
  const { email } = useSelector((state) => state.Login);
  const dispatch = useDispatch();

  const _setSubdomain = (_subdomain) => {
    dispatch(setSubdomain(_subdomain))
  }

  useEffect(() => {
    if (isSubdomainPresent)
      _setSubdomain(fullUrl[0])
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Contractor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>

      <Preloader />
      <main>
        <Navbar />
        <Row>
          <Col className="d-xl-flex d-none" xl={6}>
            <div className={styles.coverImage}>
              <img src="/assets/img/login.png" />
            </div>
          </Col>
          <Col xl={6}>
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" lg="10" className="my-2 py-2">
                  <Card className="overflow-hidden">
                    <div className="p-4 text-center">
                      <h6>Welcome Back</h6>
                      <h4>Sign in to Your Account</h4>
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        {
                          email ?
                            <Login
                              isSubdomainPresent={isSubdomainPresent}
                              subdomain={subdomain}
                            />
                            : <FindUser
                              isSubdomainPresent={isSubdomainPresent}
                              subdomain={subdomain}
                            />
                        }
                      </div>
                      <div className="mt-3 text-center">
                        <p>
                          Do not have an account ?{" "}
                          <Link to="/register" className="font-weight-medium text-primary">
                            {" "}
                            Sign up
                          </Link>{" "}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default withRouter(LoginControl);

LoginControl.propTypes = {
  history: PropTypes.object,
};
