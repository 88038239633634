import { filterParams } from "../apiHelper";
import request from "../restClient";

export const getInvoices = async (params) => {
  return await request({
    url: "invoices/",
    method: "GET",
    params: filterParams(params),
    cancelPrevious: params.cancelPrevious || true,
  });
};

export const getInvoice = async (id) => {
  return await request({
    url: `invoices/${id}/`,
    method: "GET"
  });
};

export const deleteInvoice = async (id) => {
  return await request({
    url: `invoices/${id}/`,
    method: "DELETE"
  });
};

export const unshareInvoice = async (id) => {
  return await request({
    url: `invoices/${id}/unshare`,
    method: "POST"
  });
};

export const generateInvoice = async (id, data) => {
  return await request({
    url: `invoices/${id}/generate_pdf/`,
    method: "POST",
    data
  });
};

export const editInvoice = async (id, data) => {

  return await request({
    url: `invoices/${id}/`,
    method: "PATCH",
    data
  });
};

export const shareInvoice = async (id, data) => {

  return await request({
    url: `invoices/${id}/share`,
    method: "POST",
    data
  });
};

export const sendInvoice = async (id) => {

  return await request({
    url: `invoices/${id}/send`,
    method: "POST"
  });
};

export const addInvoice = async (data) => {

  return await request({
    url: `invoices/`,
    method: "POST",
    data
  });
};
