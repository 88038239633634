import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import { Footer, Navbar } from '../../components/AuthCommon'
import styles from './css/register.module.css'
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const fullUrl = window.location.hostname.split('.')
const [_subdomain, _undefined] = window.location.hostname.split('.')
const isSubdomainPresent = fullUrl.length > 1 && _subdomain != 'app'

import { Link } from "react-router-dom";
import { setLoader } from "utils/common";
import Preloader from "components/Common/Preloader";

const Register = () => {
  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      tenant_name: '',
      full_name: "",
      email: '',
      password: '',
      tenant_phone: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Password"),
      tenant_phone: Yup.string().required("Please Enter Company Phone number"),
      tenant_name: Yup.string().required("Please Enter Your Company name"),
      full_name: Yup.string().required("Please Enter Your Name"),
      email: Yup.string().email().required("Please Enter Your Email"),
    }),
    onSubmit: (values, actions) => {
      let { full_name, ..._values } = values
      let [first_name, ...last_name] = full_name.split(" ")

      dispatch(registerUser({
        first_name: first_name,
        last_name: last_name.join(" "),
        password2: _values.password,
        ..._values
      }, actions));
    }
  });

  const {
    user,
    registrationError,
    loading
  } = useSelector((state) => state.Account);

  useEffect(() => {
    setLoader(loading);
  }, [loading]);

  useEffect(() => {
    if (isSubdomainPresent)
      window.location.href = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_HOST}/register`

  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Contractor</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>

      <Preloader />
      <main>
        <Navbar />
        <Row>
          <Col className="d-xl-flex d-none" xl={6}>
            <div className={styles.coverImage}>
              <img src="/assets/img/signup.png" />
            </div>
          </Col>
          <Col xl={6}>
            <Container>
              <Row className="justify-content-center">
                <Col xs="12" lg="10" className="my-2 py-2">
                  <Card className="overflow-hidden">
                    <div className="p-4 text-center">
                      <h6>Welcome Back</h6>
                      <h4>Sign Up Your Account</h4>
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {user && user ? (
                            <Alert color="success">
                              Register User Successfully
                            </Alert>
                          ) : null}

                          {registrationError && registrationError ? (
                            <Alert color="danger">{registrationError}</Alert>
                          ) : null}

                          <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <Input
                              name="tenant_name"
                              type="text"
                              placeholder="Enter Company Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tenant_name || ""}
                              invalid={
                                validation.touched.tenant_name && validation.errors.tenant_name ? true : false
                              }
                            />
                            {validation.touched.tenant_name && validation.errors.tenant_name ? (
                              <FormFeedback type="invalid">{validation.errors.tenant_name}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Company Phone</Label>
                            <Input
                              name="tenant_phone"
                              type="number"
                              placeholder="Enter phone"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.tenant_phone || ""}
                              invalid={
                                validation.touched.tenant_phone && validation.errors.tenant_phone ? true : false
                              }
                            />
                            {validation.touched.tenant_phone && validation.errors.tenant_phone ? (
                              <FormFeedback type="invalid">{validation.errors.tenant_phone}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Full Name</Label>
                            <Input
                              id="full_name"
                              name="full_name"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.full_name || ""}
                              invalid={
                                validation.touched.full_name && validation.errors.full_name ? true : false
                              }
                            />
                            {validation.touched.full_name && validation.errors.full_name ? (
                              <FormFeedback type="invalid">{validation.errors.full_name}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email && validation.errors.email ? true : false
                              }
                            />
                            {validation.touched.email && validation.errors.email ? (
                              <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                            ) : null}
                          </div>


                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          <p className="mb-0 mt-3">
                            <Link to="/forgot-password" style={{ float: 'right' }} className="font-weight-medium text-primary mb-1">
                              Forgot Password
                            </Link>
                          </p>

                          <div className="mt-2">
                            <button
                              className="btn btn-primary d-block w-100"
                              type="submit"
                            >
                              Sign up
                            </button>
                          </div>

                          <div className="mt-3 text-center">
                            <p>
                              Already have an account ?{" "}
                              <Link to="/login" className="font-weight-medium text-primary">
                                {" "}
                                Login
                              </Link>{" "}
                            </p>
                          </div>
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Footer />
      </main>
    </React.Fragment>
  );
};

export default Register;
