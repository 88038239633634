import React, { useEffect, useState, useRef, Fragment } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Menu, MenuItem } from '@szhsin/react-menu';

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "common/Loader";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getInvoices as onGetInvoices,
  deleteInvoice as onDeleteInvoice,
  unshareInvoice as onunshareInvoice,
  sendInvoiceToCustomer as onsendInvoiceToCustomer,
  deleteInvoiceModal,
  generateInvoice as onGenerateInvoice,
  generateInvoiceModal,
  editInvoice,
  setGeneratableInvoice
} from "store/invoices/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { setLoader, usFormat } from "utils/common";
import GenerateInvoice from './GenerateInvoice'
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Share from "./Share";

const Invoices = ({
  projectId = null,
  invoiceId = null,
  prevInvoices = [],
  onInvoiceSelect = null
}) => {
  const dispatch = useDispatch();
  const [sendInvoice, setSendInvoice] = useState(null)

  const { invoiceWithPagy, loading, deleteModal, generateModal, generatableInvoice } = useSelector(state => ({
    invoiceWithPagy: state.invoices.invoiceWithPagy,
    loading: state.invoices.loading,
    deleteModal: state.invoices.delete,
    generateModal: state.invoices.generate,
    generatableInvoice: state.invoices.generatableInvoice,
  }));
  const [unshareInvoice, setUnshareInvoice] = useState(null)
  let { results: _invoices } = invoiceWithPagy
  let invoices = invoiceId ? _invoices.filter(_invoice => _invoice.id != invoiceId) : _invoices

  const [invoice, setInvoice] = useState(null);
  const [sharedInvoice, setSharedInvoice] = useState(null);
  const [searchParams, setSearchParams] = useState({
    search: '',
    status: ''
  })
  const [alert, showAlert] = useState("")

  //pagination customization
  const pageOptions = {
    sizePerPage: invoiceWithPagy.page_size,
    totalSize: invoiceWithPagy.total,
    page: invoiceWithPagy.page,
    custom: true,
  };

  const COLS = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      text: "#",
      dataField: "index",
      sort: true,
      hidden: !!projectId,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row, rowIndex) => <>{rowIndex + 1}</>,
    },
    {
      text: "Customer",
      dataField: "customer",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.customer.first_name} ${row.customer.last_name}`}</i></b>
    },
    {
      text: "Project",
      dataField: "project",
      sort: true,
      hidden: !!projectId,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <b><i>{`${row.project.project_name}`}</i></b>
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <Fragment>
          {
            projectId ?
              <b><i>{`${row.status}`}</i></b>
              :
              <div style={{ width: '70%' }} className="mb-1">
                <Input
                  name="status"
                  type="select"
                  className="form-select"
                  onChange={event => {
                    handleInvoiceChangeStatus(row, event.target.value)
                  }}
                  value={row.status || ""}>
                  <option value=""></option>
                  <option value="New">New</option>
                  <option value="Sent">Sent</option>
                  <option value="Returned">Returned</option>
                  <option value="Canceled">Canceled</option>
                  <option value="Paid">Paid</option>
                  <option value="Credited">Credited</option>
                  <option value="Uncollectable">Uncollectable</option>
                </Input>
              </div>
          }
        </Fragment>
      ),
    },
    {
      dataField: "total_amount",
      text: "Amount",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => usFormat(row.total_amount),
    },
    {
      dataField: "due_date",
      text: "Due Date",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.due_date),
    },
    {
      dataField: "print",
      text: "Print",
      sort: true,
      hidden: !!projectId,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => <Fragment>
        {
          invoice.pdf_file ?
            <a href={invoice.pdf_file} rel="noopener noreferrer" target={"_blank"} className="me-1">
              <i className="fas fa-print text-secondary font-size-18" />
            </a>
            :
            <i onClick={() => {
              dispatch(setGeneratableInvoice(invoice))
              dispatch(generateInvoiceModal())
            }} className="fas fa-print text-secondary font-size-18 me-1" />
        }
      </Fragment>
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      hidden: !!projectId,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, invoice) => (
        <Menu menuButton={<i className="mdi mdi-dots-horizontal font-size-18" />} transition>
          <MenuItem
            onClick={() => handleEditInvoice(invoice)}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onClickDelete(invoice)}
          >
            Delete
          </MenuItem>
          <MenuItem
            onClick={() => onClickSend(invoice)}
          >
            Send to Customer
          </MenuItem>
          <MenuItem
            onClick={() => onClickShare(invoice, invoice?.is_shared_with_customer)}
          >
            {invoice?.is_shared_with_customer ? "Unshare" : "Share"}
          </MenuItem>
        </Menu>
      ),
    },
  ];


  var node = useRef();

  //delete invoice

  const onClickDelete = (invoice) => {
    setInvoice(invoice);
    dispatch(deleteInvoiceModal())
  };

  const onClickSend = (invoice) => {
    setSendInvoice(invoice);
  };

  const onClickShare = (invoice, alreadyShared) => {
    if (alreadyShared)
      setUnshareInvoice(invoice)
    else
      setSharedInvoice(invoice)
  };

  const handleDeleteInvoice = () => {
    dispatch(onDeleteInvoice(invoice))
  }

  const handleGenerateInvoice = _params => {
    dispatch(onGenerateInvoice(generatableInvoice, _params))
  }

  const { SearchBar } = Search;

  function init() {
    dispatch(onGetInvoices(true, { project_id: projectId }));
  }

  useEffect(() => {
    init()
  }, [projectId]);

  useEffect(() => {
    setLoader(!projectId && loading);
  }, [loading]);

  const history = useHistory()

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page }) => {
    if (type == "pagination")
      dispatch(onGetInvoices(true, { page, project_id: projectId, ...searchParams }));
  };

  const handleCreateInvoice = () => {
    history.push('/invoices/new')
  };

  const handleEditInvoice = invoice => {
    history.push(`/invoices/${invoice.id}/edit`)
  };

  const handleInvoiceChangeStatus = (invoice, value) => {
    setInvoice(invoice)
    showAlert(value)
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = date ? moment(new Date(date)).format("DD MMM Y") : "N/A";
    return date1;
  };

  let handleSearch = (key, value) => {
    let _searchParams = { ...searchParams, [key]: value }
    setSearchParams(_searchParams)
    dispatch(onGetInvoices(false, _searchParams));
  }

  return (
    <React.Fragment>
      <SweetAlert
        title="Please confirm the status change for this invoice"
        type="info"
        showCancel
        show={alert != ""}
        btnSize=""
        focusConfirmBtn={false}
        cancelBtnBsStyle="danger"
        confirmBtnText='Confirm'
        closeAnim={{ name: 'hideSweetAlert', duration: 100 }}
        onConfirm={() => {
          showAlert("")
          dispatch(editInvoice(invoice.id, { status: alert }, null))
        }}
        onCancel={() => showAlert("")}
      >
        <div className="d-flex justify-content-around">
          <span>Customer:  <b>{invoice?.customer.first_name} {invoice?.customer.last_name} </b></span>
          <span>Project:  <b>{invoice?.project.project_name} </b></span>
        </div>
        <h2 className="mt-3 font-weight-bolder">{usFormat(invoice?.total_amount)}</h2>,
      </SweetAlert>

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteInvoice}
        onCloseClick={() => dispatch(deleteInvoiceModal())}
      />

      <DeleteModal
        show={!!sendInvoice}
        onDeleteClick={() => dispatch(onsendInvoiceToCustomer(sendInvoice, () => setSendInvoice(null)))}
        onCloseClick={() => setSendInvoice(null)}
      />

      <DeleteModal
        show={!!unshareInvoice}
        onDeleteClick={() => dispatch(onunshareInvoice(unshareInvoice, () => setUnshareInvoice(null)))}
        onCloseClick={() => setUnshareInvoice(null)}
      />

      <Share
        invoice={sharedInvoice}
        onCloseClick={() => setSharedInvoice(null)}
      />

      <GenerateInvoice
        show={generateModal}
        invoice={generatableInvoice}
        onGenerateClick={(params = {}) => handleGenerateInvoice(params)}
        onCloseClick={() => {
          dispatch(setGeneratableInvoice(null))
          dispatch(generateInvoiceModal())
        }}
      />

      <div className={!projectId ? "page-content" : ""}>
        <MetaTags>
          <title>Invoices</title>
        </MetaTags>
        <Container fluid className={!projectId ? "" : "px-0"}>
          {!projectId && <Breadcrumbs breadcrumbItem="Invoices" />}
          <Row>
            <Col xs="12">
              <Card className={projectId ? `border` : ""}>
                {
                  projectId &&
                  <h4 className="mt-3 mx-3">Invoices</h4>
                }
                <CardBody>
                  {
                    (!loading || !projectId) ?
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={COLS}
                        data={invoices}
                      >
                        {({ paginationProps, paginationTableProps }) => {
                          return (
                            <ToolkitProvider
                              keyField="id"
                              data={invoices || []}
                              columns={COLS}
                              bootstrap4
                              search
                            >
                              {toolkitProps => {
                                return (
                                  <React.Fragment>
                                    {
                                      !projectId &&
                                      <Row className="mb-2">
                                        <Col className="d-flex" sm="6">
                                          <div className="search-box ms-2 mb-2 d-inline-block">
                                            <Label className="form-label">Customer/Project</Label>
                                            <div className="position-relative">
                                              <SearchBar
                                                searchText={searchParams.search}
                                                onSearch={text => handleSearch("search", text)} />
                                              <i className="bx bx-search-alt search-icon" />
                                            </div>
                                          </div>

                                          <div className="ms-2 mb-2 d-inline-block">
                                            <Label className="form-label">Status</Label>
                                            <Input
                                              name="status"
                                              type="select"
                                              className="form-select"
                                              onChange={e => handleSearch("status", e.target.value)}
                                              value={searchParams.status || ""}
                                            >
                                              <option value="">Status</option>
                                              <option value="New">New</option>
                                              <option value="Sent">Sent</option>
                                              <option value="Returned">Returned</option>
                                              <option value="Canceled">Canceled</option>
                                              <option value="Paid">Paid</option>
                                              <option value="Credited">Credited</option>
                                              <option value="Uncollectable">Uncollectable</option>

                                            </Input>
                                          </div>

                                        </Col>

                                        <Col sm="6">
                                          <div className="text-sm-end">
                                            <Button
                                              type="button"
                                              color="success"
                                              className="btn-rounded  mb-2 me-2"
                                              onClick={handleCreateInvoice}
                                            >
                                              <i className="mdi mdi-plus me-1" />
                                              New invoice
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    }
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            noDataIndication={"No Data found"}
                                            defaultSorted={defaultSorted}
                                            selectRow={{
                                              mode: 'checkbox',
                                              hideSelectAll: true,
                                              clickToSelect: !!projectId,
                                              hideSelectColumn: !projectId,
                                              selected: prevInvoices,
                                              onSelect: (row, isSelect) => {
                                                onInvoiceSelect(row, isSelect)
                                              },
                                              selectionRenderer: (props) => (
                                                <Input
                                                  type={props.mode}
                                                  checked={props.checked}
                                                  onChange={() => { }}
                                                />
                                              )
                                            }}
                                            classes={"table align-middle table-nowrap"}
                                            keyField="id"
                                            remote={{ search: true, pagination: true }}
                                            {...toolkitProps.baseProps}
                                            onTableChange={handleTableChange}
                                            {...paginationTableProps}
                                            ref={node}
                                          />
                                        </div>

                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )
                              }}
                            </ToolkitProvider>
                          )
                        }}
                      </PaginationProvider>
                      : loading ? <Loader /> : null
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Invoices.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  onAddNewInvoice: PropTypes.func,
  onDeleteInvoice: PropTypes.func,
  onUpdateInvoice: PropTypes.func,
};

export default Invoices;
