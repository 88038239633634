import {
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICES,
  ADD_INVOICE,
  EDIT_INVOICE,
  DELETE_INVOICE,
} from "./actionTypes";

const INIT_STATE = {
  invoiceWithPagy: {
    total: 0,
    page: 1,
    page_size: 10,
    results: []
  },
  new: false,
  edit: false,
  delete: false,
  generatableInvoice: null,
  generate: false,
  invoice: null,
  loading: false,
};

const invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        new: false,
        edit: false,
        delete: false,
        loading: action.payload.loading
      };
    case 'GET_INVOICE':
      return {
        ...state,
        invoice: null,
        loading: true
      };

    case 'SET_GENERATABLE_INVOICE':
      return {
        ...state,
        generatableInvoice: action.payload.invoice
      };

    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceWithPagy: action.payload,
      };

    case 'GET_INVOICE_SUCCESS':
      return {
        ...state,
        loading: false,
        invoice: action.payload,
      };

    case 'GET_INVOICE_FAILED':
      return {
        ...state,
        loading: false,
        invoice: null
      };

    case ADD_INVOICE:
      return {
        ...state,
        loading: true
      };

    case DELETE_INVOICE:
      return {
        ...state,
        loading: true
      };

    case 'UNSHARE_INVOICE':
      return {
        ...state,
        loading: true
      };

    case 'SEND_INVOICE':
      return {
        ...state,
        loading: true
      };

    case 'GENERATE_INVOICE':
      return {
        ...state,
        loading: true
      };

    case EDIT_INVOICE:
      return {
        ...state,
        loading: true
      };

    case GET_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "NEW_INVOICE_MODAL":
      return {
        ...state,
        new: !state.new
      };

    case "DELETE_INVOICE_MODAL":
      return {
        ...state,
        delete: !state.delete
      };

    case "GENERATE_INVOICE_MODAL":
      return {
        ...state,
        generate: !state.generate
      };

    case "EDIT_INVOICE_MODAL":
      return {
        ...state,
        edit: !state.edit
      };
    default:
      return state;
  }
};

export default invoices;
