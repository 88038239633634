import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from 'react-toastify';
import {
  GET_INVOICES,
  DELETE_INVOICE,
  ADD_INVOICE,
  EDIT_INVOICE
} from "./actionTypes";

import {
  getInvoicesSuccess,
  getInvoices,
  getInvoicesFail,
  deleteInvoiceModal,
  generateInvoiceModal,
  setGeneratableInvoice,
  getInvoiceFail,
  getInvoiceSuccess
} from "./actions";

import {
  getInvoices as getInvoicesAPI,
  sendInvoice as sendInvoiceAPI,
  getInvoice as getInvoiceAPI,
  deleteInvoice as deleteInvoiceAPI,
  unshareInvoice as unshareInvoiceAPI,
  generateInvoice as generateInvoiceAPI,
  addInvoice as addInvoiceAPI,
  editInvoice as editInvoiceAPI,
} from "api/v1";


function* fetchInvoices({ payload: { params } }) {
  try {
    const response = yield call(getInvoicesAPI, params);
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    yield put(getInvoicesFail("Something went wrong!"))
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* fetchInvoice({ payload: { id, history } }) {
  try {
    const response = yield call(getInvoiceAPI, id);
    yield put(getInvoiceSuccess(response));
  } catch (error) {
    yield put(getInvoiceFail("Object not found"))
    if (history) history.goBack()
    if (error.message != "request-cancel")
      toast.error("Something went wrong!");
  }
}

function* addInvoice({ payload: { invoice, actions, history } }) {
  try {
    let _invoice = yield call(addInvoiceAPI, invoice)
    toast.success("Invoice Created!");
    yield put(getInvoices(true));
    actions.resetForm()
    if (history)
      history.goBack()
    // do here
    yield put(setGeneratableInvoice(_invoice))
    yield put(generateInvoiceModal())

  } catch ({ errors }) {
    yield put(getInvoicesFail("Creation failed!"))
    if (errors) {
      if (["customer"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 1)
      else if (["project"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 2)
      else if ([
        "date_sent",
        "due_date",
        "status",
        "payment_method"
      ].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 3)
      else if (["services"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 4)
      else if (["expenses"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 5)

      actions.setErrors(errors)
      toast.error(`${Object.keys(errors)[0]}: ${Object.values(errors)[0].join("\n")}`)
    }
    else
      toast.error("Something went wrong!");
  }
}

function* editInvoice({ payload: { id, invoice, actions, history } }) {
  try {
    let _invoice = yield call(editInvoiceAPI, id, invoice)
    toast.success("Invoice Updated!");
    yield put(getInvoices(true));
    // do here
    if (history)
      history.push('/invoice')
    if (Object.keys(invoice).length > 1) {
      yield put(setGeneratableInvoice(_invoice))
      yield put(generateInvoiceModal())
    }


  } catch ({ errors }) {
    yield put(getInvoicesFail("Creation failed!"))
    if (errors) {
      if (["customer"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 1)
      else if (["project"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 2)
      else if ([
        "date_sent",
        "due_date",
        "status",
        "payment_method"
      ].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 3)
      else if (["services"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 4)
      else if (["expenses"].includes(Object.keys(errors)[0]))
        actions.setFieldValue('step', 5)

      if (actions)
        actions.setErrors(errors)

      toast.error(`${Object.keys(errors)[0]}: ${Object.values(errors)[0].join("\n")}`)

    } else
      toast.error("Something went wrong!");
  }
}

function* deleteInvoice({ payload: { invoice } }) {
  try {
    yield call(deleteInvoiceAPI, invoice.id);
    yield put(deleteInvoiceModal())
    yield put(getInvoices(true));
    toast.warn("Invoice Deleted!");
  } catch (error) {
    yield put(getInvoicesFail(""))
    toast.error("Something went wrong!");
  }
}

function* unshareInvoice({ payload: { invoice, actions } }) {
  try {
    yield call(unshareInvoiceAPI, invoice.id);
    actions()
    yield put(getInvoices(true));
    toast.warn("Invoice unshared with customer!");
  } catch (error) {
    yield put(getInvoicesFail(""))
    toast.error("Something went wrong!");
  }
}

function* sendInvoiceToCustomer({ payload: { invoice, actions } }) {
  try {
    yield call(sendInvoiceAPI, invoice.id);
    actions()
    yield put(getInvoices(true));
    toast.success("Invoice is sent to customer!");
  } catch (error) {
    yield put(getInvoicesFail(""))
    toast.error("Something went wrong!");
  }
}

function* generateInvoice({ payload: { invoice, params } }) {
  try {
    yield call(generateInvoiceAPI, invoice.id, params);
    yield put(generateInvoiceModal())
    yield put(getInvoices(true));
    toast.success("Invoice generated!");
  } catch (error) {
    yield put(getInvoicesFail(""))
    toast.error("Something went wrong!");
  }
}

function* invoiceSaga() {
  yield takeEvery('GET_INVOICE', fetchInvoice);
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(DELETE_INVOICE, deleteInvoice);
  yield takeEvery('UNSHARE_INVOICE', unshareInvoice);
  yield takeEvery('SEND_INVOICE', sendInvoiceToCustomer);
  yield takeEvery('GENERATE_INVOICE', generateInvoice);
  yield takeEvery(ADD_INVOICE, addInvoice);
  yield takeEvery(EDIT_INVOICE, editInvoice);
}

export default invoiceSaga;
